import React, { useEffect, useContext, useRef } from "react";
import { GlobalStateContext } from "../../context/GlobalContextProvider";
// import { TweenLite, Power3 } from "gsap";

import Seo from "../../components/seo/seo";
import Layout from "../../components/layout/layout";
import PageBanner from "../../components/common/PageBanner";
import Clients from "../../components/common/Clients";
import InteractiveVideo from "../../components/common/InteractiveVideo";
// import image from "../../assets/img/products/iLifeSavingRules.jpg";

import { StaticImage } from "gatsby-plugin-image";

const ILifeSavingRules = () => {
  const state = useContext(GlobalStateContext);
  let container = useRef(null);

  useEffect(() => {
    if (!state.showPageLoading) {
      const cn = container.current;
      setTimeout(() => {
        cn.classList.remove("hide-container");
      }, state.containerAnimationDelay * 1000);
    }
  }, [state.showPageLoading, state.containerAnimationDelay]);

  return (
    <Layout>
      <Seo
        title='iLifeSavingRules® | VIRSAT - Virtual Reality Safety Training'
        description='iLifeSavingRules® is designed to simplify, standardise, and automate the implementation of the IOGP Life-Saving Rules. Consult us now!'
      />
      <PageBanner
        title='iLifeSavingRules<sup>®</sup>'
        normalizeTitle
        subtitle='Revolutionairy change in safety'
      />
      <section
        className='main-container single-product hide-container'
        ref={container}
      >
        <div className='row top-pad bottom-pad fd-col less-mar'>
          <div className='column'>
            <p className='medium reduce_width dark no-mar center'>
              iLifeSavingRules<sup>®</sup>, is designed to Simplify,
              Standardise, and Automate the implementation of the IOGP
              Life-Saving Rules. iLifeSavingRules<sup>®</sup> digitises the
              training, testing, team engagement process, certification and
              administration aspects of the Life-Saving Rules implementation.
            </p>
            <hr className='site-border' />
          </div>
          <div className='column details-container'>
            <div className='details'>
              <p>
                It supports a four-stage learning process driven by a top-down
                deployment, utilising a visual, serious-games-based training
                method. The training is suitable for application independent of
                country, language, and culture. The ability to deliver to both
                companies and (sub)contractors in one integrated effort is
                essential to ensure that no worker is excluded.
              </p>
              <p>
                iLifeSavingRules<sup>®</sup> has a mixed delivery process
                utilising both company IT systems and employee devices (e.g.
                Smart phones). Individual competence testing is included as
                well. Continuous refreshing and enhancing the workers’
                knowledge, “life-long” learning”, is a key feature of
                iLifeSavingRules<sup>®</sup>
              </p>
            </div>
            <div className='image-container'>
              {/* <img src={image} alt='iLifeSavingRules' /> */}
              <StaticImage
                src='../../assets/img/products/iLifeSavingRules.jpg'
                alt='iLifeSavingRules'
                placeholder='blurred'
                objectFit
              />
            </div>
          </div>
        </div>
      </section>
      <Clients />
      <InteractiveVideo />
    </Layout>
  );
};

export default ILifeSavingRules;
